<template>
  <v-card flat class="rounded-t-0" :loading="loading">
    <template v-for="(group, key) in itemsGrouped" :value="true">
      <v-system-bar
        :key="'bar' + key"
        :color="key == formatDate(today, true, true) ? 'primary' : ''"
        :dark="key == formatDate(today, true, true)"
      >
        {{ key }}
        {{
          key == formatDate(today, true, true) ? "(heute)" : ""
        }}</v-system-bar
      >
      <v-list :key="'list' + key" dense class="py-0" expand>
        <EventItem v-for="item in group" :key="item.id" :value="item" />
      </v-list>
    </template>

    <v-divider />

    <v-card-actions>
      <v-btn text block small to="/events">zum Terminplan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  addDays,
  formatDatespan,
  formatDate,
  formatTimespan,
  today,
  isCurrent,
  isFuture,
  isPast,
} from "common/utils/date";
import { groupBy } from "lodash";

import EventItem from "common/components/EventItem.vue";

export default {
  components: {
    EventItem,
  },
  data() {
    return {
      items: [],
      loading: false,
      selectedItem: {},
      selectedOpen: false,
    };
  },
  computed: {
    today,
    itemsGrouped() {
      return groupBy(this.itemsMapped, (el) =>
        el.startDate == el.endDate
          ? formatDatespan(el.startDate, el.endDate, true, true)
          : "mehrtägige"
      );
    },

    itemsMapped() {
      return this.items.map((el) => {
        return {
          ...el,
          changed: this.isNew(el),
          current: this.isCurrent(el),
          past: this.isPast(el),
        };
      });
    },
  },
  methods: {
    formatDate,
    formatDatespan,
    formatTimespan,
    isCurrent,
    isFuture,
    isPast,
    isNew(item) {
      const deadline = new Date(this.$_currentTerm.startDate).getTime();
      const value = new Date(item.lastModified).getTime();
      return deadline < value;
    },
    async fetchData() {
      this.loading = true;

      const startDateNow = today();
      const endDateNow = addDays(startDateNow, 3);
      this.items = (
        await this.apiList({
          resource: "course/event",
          query: `startDate=${startDateNow}&endDate=${endDateNow}`,
        })
      ).sort((a, b) => {
        const a_start = a.startTime
          ? new Date(a.startDate + "T" + a.startTime)
          : new Date(a.startDate);
        const b_start = b.startTime
          ? new Date(b.startDate + "T" + b.startTime)
          : new Date(b.startDate);
        return a_start.getTime() > b_start.getTime()
          ? 1
          : a_start.getTime() < b_start.getTime()
          ? -1
          : 0;
      });
      this.loading = false;
      this.$emit("loaded");
    },
    select(item) {
      this.selectedItem = item;
      this.selectedOpen = true;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
